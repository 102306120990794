@font-face {
  font-family: 'vasexperts';
  src:  url('fonts/vasexperts.eot?ft5179');
  src:  url('fonts/vasexperts.eot?ft5179#iefix') format('embedded-opentype'),
    url('fonts/vasexperts.ttf?ft5179') format('truetype'),
    url('fonts/vasexperts.woff?ft5179') format('woff'),
    url('fonts/vasexperts.svg?ft5179#vasexperts') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fa-clickstream:before {
  font-family: 'vasexperts' !important;
  content: "\e900";
}
.fa-fullflow:before {
  font-family: 'vasexperts' !important;
  content: "\e901";
}
.fa-qoesubscribers:before {
  font-family: 'vasexperts' !important;
  content: "\e902";
}
.fa-rawclickstream:before {
  font-family: 'vasexperts' !important;
  content: "\e903";
}
.fa-rawfullflow:before {
  font-family: 'vasexperts' !important;
  content: "\e904";
}